import { createApp } from 'vue'
import App from './App.vue'
import Vue3TouchEvents from 'vue3-touch-events'
import router from './router'

const app = createApp(App)

require('./assets/styles/main.sass')

// register global components
const globalComponents = require.context('./components/global', true, /\.vue$/i)
globalComponents.keys().map(key => {
    if (key.includes('icons/')) {
        const componentConfig = globalComponents(key)
        // remove from filename './' from beginning, '.vue' at the end
        const componentName = `Icon${key.substr(0, key.length - 4).substr(2)}`.replace('icons/', '')
        // register component
        app.component(componentName, componentConfig.default || componentConfig)
    } else {
        app.component(
            globalComponents(key).default.name ?? key.split('/').pop()?.split('.')[0],
            globalComponents(key).default,
        )
    }
})

//  register service worker
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(`${process.env.BASE_URL}service-worker.js`).then(reg => {
        reg.onupdatefound = () => {
            const installingWorker = reg.installing
            if (installingWorker) {
                installingWorker.onstatechange = () => {
                    if (
                        installingWorker?.state === 'installed' &&
                        navigator.serviceWorker.controller
                    ) {
                        // window.location.reload()
                    }
                }
            }
        }
    })
}

app.use(router).use(Vue3TouchEvents).mount('#app')
